// font sizes
$f-xxs :0.625rem;      // 10px
$f-xs :0.75rem;      // 12px
$f-xs :0.875rem;      // 14px
$f-s :1rem;      // 16px
$f-m :1.25rem;   // 20px
$f-l :1.563rem;  // 25px
$f-xl :1.875rem;  // 30px
$f-xxl :2.5rem;    // 40px
$f-xxxl :3.125rem;  // 50px
$f-xxxxl :3.75rem;   // 60px

// line-height
$f-line: 1.6rem;

// units
$u-xs: 5px;
$u-s: 10px;
$u-m: 20px;
$u-ml: 25px;
$u-l: 40px;
$u-xl: 50px;
$u-xxl: 100px;
$u-xxxl: 130px;

// icons 
$icon-xs: 20px;
$icon-s: 30px;
$icon-m: 40px;
$icon-l: 50px;

// border-radius
$b-radius: 20px;

// z-index
$-overlay: 20; 
$-header: 15; 
$-normal: 10; 
$-background: -1; 

// media-screen width
$m-max-width: 1200px;
$m-tablet-width: 850px;
$m-mobile-width: 480px;

// icons 
$s-icon-s: 20px;
$s-icon-m: 30px;
$s-icon-l: 40px;

// elements
$s-header-height: $u-l;

// transition-time 
$s-time-01: 0.1s;
$s-time-02: 0.2s;
$s-time-04: 0.4s;
$s-time-1: 1s;
$s-time-2: 2s;
$s-time-4: 4s;
$s-time-8: 8s;