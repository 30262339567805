footer {
  padding: $u-l 0 0;
  
  img.group {
    width: 100%;
    height: 100%;
    transform: scale(1.01);
  }
  
  @media only screen and (max-width: $m-tablet-width) {
    padding: $u-l 0 0;
  }
}