.slide-container {
  position: relative;
  margin: auto;
  overflow: hidden;
  animation: 1s fadeFromMedium ease;
  width: $m-max-width;

  .slides {
    display: flex;
    width: 100%;
    transform: translate(0, 0);
    transition: all $s-time-04 ease;

    .slide {
      width: $m-max-width;
      transition: all $s-time-04 ease-out;

      @media only screen and (max-width: $m-max-width) {
        width: 800px;
      }

      .image-container {
        width: $m-max-width;
        padding: 0 $u-xs;
        transition: all $s-time-04 ease-out;

        @media only screen and (max-width: $m-max-width) {
          width: 800px;
        }

        &__row {
          display: flex;
          justify-content: space-between;

          .image-overlay {
            position: relative;
            height: 355px;
            width: 100%;
            border-radius: $b-radius;
            box-shadow: $sh-normal;
            overflow: hidden;

            &:first-of-type {
              margin: 0 $u-l $u-l 0;
            }

            &__image {
              width: inherit;
              height: inherit;
              transition: all $s-time-04 ease-out;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
              }
            }

            
            .descr-overlay {
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-radius: $b-radius;
              background: $g-pink-trans;
              @include font-headline;
              transition: all $s-time-02 ease-out;
              padding: $u-m;
              text-align: center;

              &__title {
                font-size: $f-xxl;
              }
              
              &__text {
                font-size: $f-m;
              }
              
              &__divider {
                width: $u-xxl;
                height: $u-l;
                background: url(./assets/icons/divider.svg) no-repeat center;
              }
              
              &:hover {
                backdrop-filter: blur($u-xs) saturate(0);
                background-color: $c-ci-pink-80;
                opacity: 1;
              }
            }

            @media only screen and (max-width: $m-max-width) {
              height: 230px;

              .descr-overlay {
                &__title {
                  font-size: $f-xl;
                }
              }
            }

          }
        }
      }
    }
  }
  
  @media only screen and (max-width: $m-max-width) {
    width: 800px;
  }

  @media only screen and (max-width: $m-tablet-width) {
    display: none;
  }


  &--mobile {
    width: 440px;
    display: none;
  
    .slides {
      width: inherit;
      transition: all $s-time-04 ease;
      margin-bottom: $u-m;
      
      .slide {
        width: inherit;
        
        .image-overlay {
          width: inherit;
          position: relative;
          
          &__image {
            overflow: hidden;
            box-shadow: $sh-normal;
            border-radius: $b-radius;
            margin: 0 $u-xs;
            height: 275px;
            transition: all $s-time-04 ease-out;
            
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: top center;
            }
          }
          
          .descr-overlay {
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: $b-radius;
            background: $g-pink-trans;
            @include font-headline;
            transition: all $s-time-02 ease-out;
            padding: $u-m;
            text-align: center;
            
            &__title {
              font-size: $f-xl;
              text-align: center;
            }
            
            &__text {
              font-size: $f-m;
            }
            
            &__divider {
              width: $u-xxl;
              height: $u-l;
              background: url(./assets/icons/divider.svg) no-repeat center;
            }
            
            &:hover {
              backdrop-filter: blur($u-xs) saturate(0);
              background-color: $c-ci-pink-80;
              opacity: 1;
            }
          }
        }
      }
    }
  
    @media only screen and (max-width: $m-tablet-width) {
      display: block;
    }
    
    @media only screen and (max-width: $m-mobile-width) {
      width: 335px;
      
      .slides {
        .slide {
          .image-overlay {
            &__image {
              height: 209.4px;
            }
          }
        }
      }
    }

  }
}