.contact-container {
  display: grid;
  align-items: center;
  grid-template-columns: $u-l 1fr;
  grid-gap: $u-s $u-s;

  &--icon {
    border: 2px solid $c-ci-pink;
    border-radius: $u-m;
    padding: $u-xs;
    margin-right: $u-s;
  }
}