
@font-face {
  font-family: "roboto reg";
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "roboto bold";
  src: url("./assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "montserrat bold";
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "montserrat reg";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "anton";
  src: url("./assets/fonts/anton/Anton-Regular.ttf") format("truetype");
}


@mixin font-base {
  font-family: 'roboto reg', sans-serif;
}

@mixin font-base-bold{
  font-family: 'roboto bold', sans-serif;
}

@mixin font-nav {
  font-family: 'montserrat reg', sans-serif;
}

@mixin font-headline {
  font-family: 'montserrat bold', sans-serif;
}

@mixin font-slogan {
  font-family: 'anton', sans-serif;
}
