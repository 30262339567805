.nav-menu {
  @include font-nav;
  font-size: $f-s;
  padding-right: $u-m;

  .menu-links {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    transform: translate(0%, 0%);
    height: 0;
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    
    .button.button--primary {
      height: 0;
      padding: 0;
      overflow: hidden;
      color: $c-black;

      &:hover {
        text-decoration: none;
      }
    }
  }
}