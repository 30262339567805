header {
  background-color: $c-white-80;
  backdrop-filter: blur($u-m);
  border-bottom: 1px solid $c-black-10;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  overflow: hidden;
  z-index: $-header;
  transition: all $s-time-02;
  
  .header-content {
    min-height: 92px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $m-max-width;
    padding: $u-m 0 ;
    margin: auto;
    transition: all $s-time-02;
    
    @media only screen and (max-width: $m-max-width) {
      margin: 0 0 0 $u-m;
    }

    @media only screen and (max-width: $m-mobile-width) {
      min-height: 65px;
      padding: 5px;
    }
  }
}