.meta-overlay {
  display: none;

  &__content {
    .section {
      padding: 0 $u-m;
      margin: 0;
    }

    h2 {
      font-size: $f-xl;
      line-height: $f-xxl;
      text-align: left;
      margin: 0 0 $u-m;
    }
    
    h3 {
      font-size: $f-l;
      line-height: $f-xl;
      margin: $u-s 0;
    }
  }

  @media only screen and (max-width: $m-tablet-width) {
    &__content {
      .section {
        padding: $u-s;
      }

      h2 {
        font-size: $f-m;
        line-height: $f-l;
      }

      h3 {
        font-size: $f-s;
        line-height: $f-m;
        margin: $u-xs 0;
      }
    }
  }

  @media only screen and (max-width: $m-mobile-width) {
  }
}