@keyframes spinIn {
  to {
    transform: translate(-6px, 1px) rotate(-360deg) scale(1.1);
  }
}

@keyframes rotate {
  from {
    transform: scale(0.95) translate(0, 0) rotate(0deg);
  }
  to {
    transform: scale(0.95) translate(0, 0) rotate(360deg);
  }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $c-ci-sun; }
}

@keyframes bubblePopIn {
  from {
    opacity: 0;
    transform: scale(0.7) translate(-70%, -50%);
  }
}

@keyframes fadeFromLarge {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
}

@keyframes fadeFromMedium {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
}

@keyframes jump {
  0% {
    filter: blur(5px);
    transform: translateY(350%) scale(0.1, 2);
  }
  25% {
    filter: blur(10px);
    transform: translateY(500%) scale(0.5, 4);
  }
  50% {
    filter: blur(0px);
    transform: translateY(-70%) scale(1, 1);
  }
  
  75% {
    transform: translateY(25%) scale(1.5, 0.5);
  }
  100% {
    transform: translateY(0%) scale(1, 1);
  }
}

@keyframes zoomIn {
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes scaleLarge {
  to {
    width: 190px;
    height: 180px;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes dropShadow {
  to {
    filter: drop-shadow(-6px -6px 0px rgba(0, 0, 0, 0.1));
  }
}