// INFO: Adjust the auto scrolling position from nav here!!
.anchor {
  position: absolute;
  top:-8%;
  left:0;
  width: 100%;
  // height: 150px;
  // border-top: 1px solid rgb(0, 0, 0);

  &--start {
    top: -50%;
  }

  &--agentur {
    top: -15%;
  }

  &--projects {
    top: -24%;
  }

  &--customers {
    top: -55%;
  }

  &--contact {
    top: -28%;
  }

  @media only screen and (max-width: $m-tablet-width) {
    
    &--start {
      top: -100%;
    }
    
    &--agentur {
      top: -15%;
    }

    &--projects {
      top: -40%;
    }

    &--customers {
      top: -50%;
    }

    &--contact {
      top: -27%;
    }
  }

  @media only screen and (max-width: $m-mobile-width) {
    
    &--start {
      top: -200%;
    }
    
    &--agentur {
      top: -10%;
    }

    &--projects {
      top: -34%;
    }

    &--customers {
      top: -37%;
    }

    &--contact {
      top: -26%;
    }
  }
}