.header-logo {
  display: flex;
  overflow: hidden;
  
  &.rollIn {
    img {
      height: $u-xl;

      &.text {
        margin-top: 0;
        margin-left: 0;
      }

      &.swirl {
        height: $u-m;
        width: $u-m;
        opacity: 1;
      }


      @media only screen and (max-width: $m-tablet-width) {

        &.text {
          display: none;
        }

        &.swirl {
          height: $u-xl;
          width: $u-xl;
          object-fit: contain;
          transform: scale(0.95) translate(0, 0) rotate(360deg);
        }
      }
  
      @media only screen and (max-width: $m-mobile-width) {
        &.text {
          display: none;
  
        }
        &.swirl {
          height: $u-xl;
          width: $u-xl;
          object-fit: contain;
          transform: scale(0.95) translate(0, 0) rotate(360deg);
        }
      }

    }
  }
  
  img {
    transition: all $s-time-04 ease;
    height: 0;
    
    &.text {
      transition: all $s-time-1 ease;
      margin-top: -100%;
      margin-left: -100%;
      width: 287px;
      height: 50px;
    }

    &.swirl {
      opacity: 0;
      height: $u-xl;
      width: $u-xl;
      transition: all 1.5s ease;
      animation: spinIn $s-time-2 ease-out;
      transform: translate(-6px, 1px) rotate(360deg) scale(1.1);

    }

    @media only screen and (max-width: $m-tablet-width) {
      &.swirl {
        // transition: all $s-time-04 ease;
        height: $u-m;
        animation: rotate $s-time-8 linear infinite;
      }
    }
  }
}

