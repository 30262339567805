.image-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all $s-time-2 cubic-bezier(0.96,-0.37, 0.08, 1.47);
  transform: scaleX(1);
  min-height: 600px;
  
  &__item {
    opacity: 1;
    transition: all $s-time-2 cubic-bezier(0.96,-0.37, 0.08, 1.47);
    max-width: 1000px;
    width: 100%;
  }

  &--cq {
    .image-box__item {
      width: 75%;
      margin-bottom: -5%;
    }
  }

  @media only screen and (max-width: $m-tablet-width) {
    min-height: 200px;

    &--cq {
      .image-box__item {
        width: 75%;
        margin-bottom: -5%;
      }
    }
  }

  @media only screen and (max-width: $m-mobile-width) {
    min-height: 200px;
    &--cq {
      .image-box__item {
        width: 75%;
        margin-bottom: -10%;
      }
    }
  }
}

.intro-anim {
  .image-box {
    min-height: 0px;
    transform: scaleX(0.8);

    &__item {
      opacity: 0;
      max-width: 0px;
    }
  }
}