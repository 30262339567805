// TODO: REFACTOR!
// -- primary
// -- pink
// -- business card

.info-card {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: $b-radius;
  box-shadow: $sh-normal;
  border: 1px solid $c-black-10;
  margin: 20px auto;
  transition: all $s-time-02 ease-out;
  padding: $u-l;
  line-height: $f-l;


  &--business-card {
    max-width: 100%;
    width: 760px;
    background-color: $c-white;

    &.active {
      opacity: 1;
    }

    &:hover {
      &:nth-child(odd) {
        box-shadow: $sh-hard-right;
        transform: rotateZ(-1deg);
      }
      
      &:nth-child(even) {
        box-shadow: $sh-hard-left;
        transform: rotateZ(1deg);
      }
    }

    @media only screen and (max-width: $m-mobile-width) {
      padding: $u-m $u-l;
    }
  }
  
  &__logo {
    margin: $u-s 0;
    transition: all $s-time-04 ease;
    max-height: 100%;
    max-width: 100%;
    width: 280px;
    height: 70px;
    
    @media only screen and (max-width: $m-tablet-width) {
      width: 180px;
      height: 40px;
    }
    
    @media only screen and (max-width: $m-mobile-width) {
      margin: $u-xs 0;
      width: 140px;
      height: 40px;
    }

    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
      object-position: center;
    }
    
    &--webadelic {
      margin-top: -$u-m;
      
      @media only screen and (max-width: $m-mobile-width) {
        margin-top: -$u-xs;
      }
    }
  }

  &--interactive {
    max-width: 100%;
    width: 760px;
    background-color: $c-white;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    &:hover {
      &:nth-child(odd) {
        box-shadow: $sh-hard-right;
        transform: rotateZ(-1deg);
      }
      
      &:nth-child(even) {
        box-shadow: $sh-hard-left;
        transform: rotateZ(1deg);
      }
    }

    @media only screen and (max-width: $m-mobile-width) {
      padding: $u-m $u-s;
    }
  }

  &--list {
    height: 0;
    width: 0;
    padding: 0;
    background-color: $c-white;
    overflow: hidden;
    align-items: flex-start;
    opacity: 0;
    
    @media only screen and (max-width: $m-mobile-width) {
      padding: $u-m $u-s;
    }
  }

  &--empty {
    border:none;
    box-shadow: none;

    @media only screen and (max-width: $m-tablet-width) {
      display: none;
    }
  }
  
  &--pink {
    width: 100%;
    height: 100%;
    box-shadow: none;
    padding: $u-xl $u-xxl;
    text-align: center;
    justify-content: center;
    background-color: $c-ci-pink;
    color: $c-white;
    img {
      transition: all $s-time-04;
      margin-bottom: -410px;

      &.info-card__image--max-1200 {
        @media only screen and (max-width: $m-max-width) {
          opacity: 0;
          transform: translate(-100%, 0);
        }
      }
    }
    
    .info-card__text {
      @include font-base-bold
    }
  }

  &__icon {
    background-color: $c-ci-sun-light;
    border-radius: $icon-l;
    padding: $u-ml;
    margin: 0 $u-l 0 0;
    flex: 0 0 auto;

    img {
      width: $icon-l;
      height: $icon-l;
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: $u-m;
    hyphens: auto;
  }

  &__text {

    &--pink {
      @include font-headline;
      font-size: $f-xl;
      margin-top: $u-m;
      
      a {
        color: $c-ci-pink;
      }
    }
  }

  @media only screen and (max-width: $m-tablet-width) {

    &__text {

      &--pink {
        @include font-headline;
        font-size: $f-m;
      }
    }

    &--interactive {
      padding: $u-l $u-s;
    }

    &--top {
      padding-top: 120px !important;
    }

    &--padd-top {
      padding-top: 120px !important;
    }
  }

  @media only screen and (max-width: $m-mobile-width) {

    &__text {

      &--pink {
        @include font-headline;
        font-size: $f-s;
      }
    }

    &--top {
      padding-top: 80px !important;
    }

    &__extra-text {
      padding: 20px 20px;
    }

    &--pink {
      padding: $u-l $u-l;
    }
    &__title {
      margin-bottom: $u-s;
    }

    &__icon {
      padding: $u-m;
      margin: 0 $u-s;

      img {
        width: $icon-s;
        height: $icon-s;
      }
    }
  }
}