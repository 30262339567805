.customers {
  margin-top: $u-xl;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.active {
    height: auto;

    .customers-row {
      display: flex;
      height: 130px;
      margin-bottom: $u-m;
    }
  }

  &-row {
    display: none;
    width: fit-content;
    width: -moz-fit-content;
    height: 0;
    overflow: hidden;
    align-items: center;
    transition: height $s-time-04 ease;
    
    &.fixed {
      display: flex;
      opacity: 1;
      height: 100%;
    }
    @media only screen and (max-width: $m-mobile-width) {
      width: 33%;
    }
  }

  &__img {
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $u-s $u-xs;
    opacity: 1;
    padding: $u-s;

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all $s-time-04;
      opacity: 0.7 !important;
      filter: saturate(0);

      &:hover {
        transform: scale(1.05) !important;
        opacity: 1 !important;
        filter: saturate(1) !important;
      }
    }

    @media only screen and (max-width: $m-mobile-width) {
      width: 100%;
    }
  }

  @media only screen and (max-width: $m-tablet-width) {
    height: auto;
  }
}