/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "roboto reg";
  src: url("./assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "roboto bold";
  src: url("./assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat bold";
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat reg";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "anton";
  src: url("./assets/fonts/anton/Anton-Regular.ttf") format("truetype");
}
* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  overflow-x: hidden;
}

body {
  position: relative;
  font-family: "roboto reg", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  overflow-y: scroll;
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 92px;
}
body.noAction {
  overflow: hidden;
}

div {
  box-sizing: border-box;
}

p {
  line-height: 1.6rem;
}

a {
  text-decoration: none;
  color: #420A5D;
}
a:hover {
  text-decoration: underline;
}

ul {
  margin: 0 0 20px 20px;
  list-style: inside;
  line-height: 1.6rem;
  list-style-position: outside;
}
ul li {
  margin-bottom: 10px;
}

h1 {
  font-family: "montserrat bold", sans-serif;
  font-size: 3.125rem;
  margin-bottom: 50px;
  text-align: center;
  transition: all 0.2s ease-out;
}

h2 {
  font-family: "montserrat bold", sans-serif;
  font-size: 2.5rem;
  text-align: center;
  margin: 40px 0;
  color: #420A5D;
  transition: all 0.2s ease-out;
}

h3 {
  font-family: "montserrat bold", sans-serif;
  font-size: 1.875rem;
  margin-bottom: 20px;
  color: #420A5D;
  transition: all 0.2s ease-out;
  line-height: 2.5rem;
}

h4 {
  font-family: "montserrat bold", sans-serif;
  font-size: 1.563rem;
  margin-bottom: 10px;
  transition: all 0.2s ease-out;
  line-height: 1.875rem;
}

strong {
  font-family: "montserrat bold", sans-serif;
}

:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.upper {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

address {
  line-height: 1.6rem;
}

@media only screen and (max-width: 480px) {
  body {
    font-size: 0.875rem;
    scroll-padding-top: 65px;
  }
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 1.875rem;
    margin: 20px 0;
  }
  h3 {
    font-size: 1.25rem;
    margin-bottom: 5px;
    line-height: 1.563rem;
  }
  h4 {
    font-size: 1rem;
    margin-bottom: 5px;
  }
}
header {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 15;
  transition: all 0.2s;
}
header .header-content {
  min-height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  padding: 20px 0;
  margin: auto;
  transition: all 0.2s;
}
@media only screen and (max-width: 1200px) {
  header .header-content {
    margin: 0 0 0 20px;
  }
}
@media only screen and (max-width: 480px) {
  header .header-content {
    min-height: 65px;
    padding: 5px;
  }
}

footer {
  padding: 40px 0 0;
}
footer img.group {
  width: 100%;
  height: 100%;
  transform: scale(1.01);
}
@media only screen and (max-width: 850px) {
  footer {
    padding: 40px 0 0;
  }
}

@keyframes spinIn {
  to {
    transform: translate(-6px, 1px) rotate(-360deg) scale(1.1);
  }
}
@keyframes rotate {
  from {
    transform: scale(0.95) translate(0, 0) rotate(0deg);
  }
  to {
    transform: scale(0.95) translate(0, 0) rotate(360deg);
  }
}
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #EA6B06;
  }
}
@keyframes bubblePopIn {
  from {
    opacity: 0;
    transform: scale(0.7) translate(-70%, -50%);
  }
}
@keyframes fadeFromLarge {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
}
@keyframes fadeFromMedium {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
}
@keyframes jump {
  0% {
    filter: blur(5px);
    transform: translateY(350%) scale(0.1, 2);
  }
  25% {
    filter: blur(10px);
    transform: translateY(500%) scale(0.5, 4);
  }
  50% {
    filter: blur(0px);
    transform: translateY(-70%) scale(1, 1);
  }
  75% {
    transform: translateY(25%) scale(1.5, 0.5);
  }
  100% {
    transform: translateY(0%) scale(1, 1);
  }
}
@keyframes zoomIn {
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@keyframes scaleLarge {
  to {
    width: 190px;
    height: 180px;
  }
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
@keyframes dropShadow {
  to {
    filter: drop-shadow(-6px -6px 0px rgba(0, 0, 0, 0.1));
  }
}
.anchor {
  position: absolute;
  top: -8%;
  left: 0;
  width: 100%;
}
.anchor--start {
  top: -50%;
}
.anchor--agentur {
  top: -15%;
}
.anchor--projects {
  top: -24%;
}
.anchor--customers {
  top: -55%;
}
.anchor--contact {
  top: -28%;
}
@media only screen and (max-width: 850px) {
  .anchor--start {
    top: -100%;
  }
  .anchor--agentur {
    top: -15%;
  }
  .anchor--projects {
    top: -40%;
  }
  .anchor--customers {
    top: -50%;
  }
  .anchor--contact {
    top: -27%;
  }
}
@media only screen and (max-width: 480px) {
  .anchor--start {
    top: -200%;
  }
  .anchor--agentur {
    top: -10%;
  }
  .anchor--projects {
    top: -34%;
  }
  .anchor--customers {
    top: -37%;
  }
  .anchor--contact {
    top: -26%;
  }
}

.button {
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  transition: all 0.1s ease-out;
}
.button__title {
  color: #D41A75;
}
.button--primary {
  font-family: "montserrat reg", sans-serif;
  border-radius: 5px;
  padding: 5px 15px;
  margin-left: 5px;
}
.button--primary:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.button--primary.active {
  pointer-events: none;
}
.button--primary.active, .button--primary:active {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
.button--secondary {
  border-radius: 20px;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
}
.button--secondary--sun {
  background-color: #FFF4EC;
}
.button--secondary--white {
  background-color: white;
}
.button--secondary .button__img {
  margin: -20px -30px -20px 0;
  width: 50px;
  height: 50px;
  transform: rotate(-90deg);
  object-position: center;
  object-fit: contain;
  transition: all 0.1s ease;
}
.button--secondary:hover {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}
.button--secondary.active .button__img {
  transform: rotate(-270deg);
}
.button--close {
  padding: 5px;
  margin: auto -32px auto 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #D41A75;
  border-radius: 20px;
  transition: all 0.1s ease-out;
}
.button--close:hover {
  transform: scale(1.1);
}
.button--arrow {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 50px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 auto;
}
.button--arrow:nth-of-type(1) {
  background-image: url(./assets/icons/arrow.svg);
}
.button--arrow:nth-of-type(2) {
  transform: rotate(180deg);
  background-image: url(./assets/icons/arrow.svg);
}
.button--arrow:nth-of-type(2):active {
  transform: scale(0.9) rotate(180deg);
}
.button--arrow:hover {
  border: 1px solid #D41A75;
}
.button--arrow:active {
  box-shadow: none;
  filter: brightness(0.89);
  transform: scale(0.9);
}
.button--burger {
  display: block;
  cursor: pointer;
  transition: all 0.1s;
}
.button--burger span {
  display: block;
  width: 40px;
  height: 5px;
  margin-left: 50px;
  margin-bottom: 5px;
  position: relative;
  background: #D41A75;
  border-radius: 20px;
  transform-origin: 4px 0px;
  transition: all 0.1s ease;
}
.button:active {
  transform: scale(0.95);
}

.burger-menu {
  margin-top: 10px;
}
.burger-menu #burger-btn {
  display: none;
}
.burger-menu #burger-btn:checked ~ label span:nth-last-child(1) {
  transform: rotate(-45deg) translate(-4px, -1px);
}
.burger-menu #burger-btn:checked ~ label span:nth-last-child(2) {
  transform: rotate(45deg) scale(0);
}
.burger-menu #burger-btn:checked ~ label span:nth-last-child(3) {
  transform: rotate(45deg);
}
.burger-menu #burger-btn:checked ~ .menu-links {
  height: 250px;
  transform: translate(0%, 0);
}
.burger-menu #burger-btn:checked ~ .menu-links .button.button--primary {
  margin: 10px 0;
  height: 30px;
  padding: 8px 15px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-container .indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-container .indicator-container input {
  display: none;
}
.button-container .indicator-container label {
  cursor: pointer;
  height: 10px;
  width: 30px;
  margin-left: 10px;
  border-radius: 20px;
  background-color: rgba(213, 26, 117, 0.2);
  transition: 0.2s ease;
}
.button-container .indicator-container label.mobile {
  height: 20px;
  width: 5px;
  margin-left: 5px;
  pointer-events: none;
}
.button-container .indicator-container label:hover {
  border-radius: 20px;
  height: 30px;
}
.button-container .indicator-container :checked + label {
  pointer-events: none;
  background-color: #D41A75;
  width: 10px;
}
.button-container .indicator-container :checked + label.mobile {
  height: 5px;
  width: 5px;
}

.contact-container {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr;
  grid-gap: 10px 10px;
}
.contact-container--icon {
  border: 2px solid #D41A75;
  border-radius: 20px;
  padding: 5px;
  margin-right: 10px;
}

.customers {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.customers.active {
  height: auto;
}
.customers.active .customers-row {
  display: flex;
  height: 130px;
  margin-bottom: 20px;
}
.customers-row {
  display: none;
  width: fit-content;
  width: -moz-fit-content;
  height: 0;
  overflow: hidden;
  align-items: center;
  transition: height 0.4s ease;
}
.customers-row.fixed {
  display: flex;
  opacity: 1;
  height: 100%;
}
@media only screen and (max-width: 480px) {
  .customers-row {
    width: 33%;
  }
}
.customers__img {
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 5px;
  opacity: 1;
  padding: 10px;
}
.customers__img img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.4s;
  opacity: 0.7 !important;
  filter: saturate(0);
}
.customers__img img:hover {
  transform: scale(1.05) !important;
  opacity: 1 !important;
  filter: saturate(1) !important;
}
@media only screen and (max-width: 480px) {
  .customers__img {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .customers {
    height: auto;
  }
}

.header-logo {
  display: flex;
  overflow: hidden;
}
.header-logo.rollIn img {
  height: 50px;
}
.header-logo.rollIn img.text {
  margin-top: 0;
  margin-left: 0;
}
.header-logo.rollIn img.swirl {
  height: 20px;
  width: 20px;
  opacity: 1;
}
@media only screen and (max-width: 850px) {
  .header-logo.rollIn img.text {
    display: none;
  }
  .header-logo.rollIn img.swirl {
    height: 50px;
    width: 50px;
    object-fit: contain;
    transform: scale(0.95) translate(0, 0) rotate(360deg);
  }
}
@media only screen and (max-width: 480px) {
  .header-logo.rollIn img.text {
    display: none;
  }
  .header-logo.rollIn img.swirl {
    height: 50px;
    width: 50px;
    object-fit: contain;
    transform: scale(0.95) translate(0, 0) rotate(360deg);
  }
}
.header-logo img {
  transition: all 0.4s ease;
  height: 0;
}
.header-logo img.text {
  transition: all 1s ease;
  margin-top: -100%;
  margin-left: -100%;
  width: 287px;
  height: 50px;
}
.header-logo img.swirl {
  opacity: 0;
  height: 50px;
  width: 50px;
  transition: all 1.5s ease;
  animation: spinIn 2s ease-out;
  transform: translate(-6px, 1px) rotate(360deg) scale(1.1);
}
@media only screen and (max-width: 850px) {
  .header-logo img.swirl {
    height: 20px;
    animation: rotate 8s linear infinite;
  }
}

.image-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 2s cubic-bezier(0.96, -0.37, 0.08, 1.47);
  transform: scaleX(1);
  min-height: 600px;
}
.image-box__item {
  opacity: 1;
  transition: all 2s cubic-bezier(0.96, -0.37, 0.08, 1.47);
  max-width: 1000px;
  width: 100%;
}
.image-box--cq .image-box__item {
  width: 75%;
  margin-bottom: -5%;
}
@media only screen and (max-width: 850px) {
  .image-box {
    min-height: 200px;
  }
  .image-box--cq .image-box__item {
    width: 75%;
    margin-bottom: -5%;
  }
}
@media only screen and (max-width: 480px) {
  .image-box {
    min-height: 200px;
  }
  .image-box--cq .image-box__item {
    width: 75%;
    margin-bottom: -10%;
  }
}

.intro-anim .image-box {
  min-height: 0px;
  transform: scaleX(0.8);
}
.intro-anim .image-box__item {
  opacity: 0;
  max-width: 0px;
}

.info-card {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  transition: all 0.2s ease-out;
  padding: 40px;
  line-height: 1.563rem;
}
.info-card--business-card {
  max-width: 100%;
  width: 760px;
  background-color: white;
}
.info-card--business-card.active {
  opacity: 1;
}
.info-card--business-card:hover:nth-child(odd) {
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.2);
  transform: rotateZ(-1deg);
}
.info-card--business-card:hover:nth-child(even) {
  box-shadow: -6px 6px 0px 0px rgba(0, 0, 0, 0.2);
  transform: rotateZ(1deg);
}
@media only screen and (max-width: 480px) {
  .info-card--business-card {
    padding: 20px 40px;
  }
}
.info-card__logo {
  margin: 10px 0;
  transition: all 0.4s ease;
  max-height: 100%;
  max-width: 100%;
  width: 280px;
  height: 70px;
}
@media only screen and (max-width: 850px) {
  .info-card__logo {
    width: 180px;
    height: 40px;
  }
}
@media only screen and (max-width: 480px) {
  .info-card__logo {
    margin: 5px 0;
    width: 140px;
    height: 40px;
  }
}
.info-card__logo img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  object-position: center;
}
.info-card__logo--webadelic {
  margin-top: -20px;
}
@media only screen and (max-width: 480px) {
  .info-card__logo--webadelic {
    margin-top: -5px;
  }
}
.info-card--interactive {
  max-width: 100%;
  width: 760px;
  background-color: white;
  opacity: 0;
}
.info-card--interactive.active {
  opacity: 1;
}
.info-card--interactive:hover:nth-child(odd) {
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.2);
  transform: rotateZ(-1deg);
}
.info-card--interactive:hover:nth-child(even) {
  box-shadow: -6px 6px 0px 0px rgba(0, 0, 0, 0.2);
  transform: rotateZ(1deg);
}
@media only screen and (max-width: 480px) {
  .info-card--interactive {
    padding: 20px 10px;
  }
}
.info-card--list {
  height: 0;
  width: 0;
  padding: 0;
  background-color: white;
  overflow: hidden;
  align-items: flex-start;
  opacity: 0;
}
@media only screen and (max-width: 480px) {
  .info-card--list {
    padding: 20px 10px;
  }
}
.info-card--empty {
  border: none;
  box-shadow: none;
}
@media only screen and (max-width: 850px) {
  .info-card--empty {
    display: none;
  }
}
.info-card--pink {
  width: 100%;
  height: 100%;
  box-shadow: none;
  padding: 50px 100px;
  text-align: center;
  justify-content: center;
  background-color: #D41A75;
  color: white;
}
.info-card--pink img {
  transition: all 0.4s;
  margin-bottom: -410px;
}
@media only screen and (max-width: 1200px) {
  .info-card--pink img.info-card__image--max-1200 {
    opacity: 0;
    transform: translate(-100%, 0);
  }
}
.info-card--pink .info-card__text {
  font-family: "roboto bold", sans-serif;
}
.info-card__icon {
  background-color: #FFF4EC;
  border-radius: 50px;
  padding: 25px;
  margin: 0 40px 0 0;
  flex: 0 0 auto;
}
.info-card__icon img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.info-card__title {
  margin-bottom: 20px;
  hyphens: auto;
}
.info-card__text--pink {
  font-family: "montserrat bold", sans-serif;
  font-size: 1.875rem;
  margin-top: 20px;
}
.info-card__text--pink a {
  color: #D41A75;
}
@media only screen and (max-width: 850px) {
  .info-card__text--pink {
    font-family: "montserrat bold", sans-serif;
    font-size: 1.25rem;
  }
  .info-card--interactive {
    padding: 40px 10px;
  }
  .info-card--top {
    padding-top: 120px !important;
  }
  .info-card--padd-top {
    padding-top: 120px !important;
  }
}
@media only screen and (max-width: 480px) {
  .info-card__text--pink {
    font-family: "montserrat bold", sans-serif;
    font-size: 1rem;
  }
  .info-card--top {
    padding-top: 80px !important;
  }
  .info-card__extra-text {
    padding: 20px 20px;
  }
  .info-card--pink {
    padding: 40px 40px;
  }
  .info-card__title {
    margin-bottom: 10px;
  }
  .info-card__icon {
    padding: 20px;
    margin: 0 10px;
  }
  .info-card__icon img {
    width: 30px;
    height: 30px;
  }
}

.list--no-li-style {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}
.list__item--thick {
  font-family: "montserrat bold", sans-serif;
}
.list__item--thin {
  font-family: "montserrat reg", sans-serif;
}

.logo-paralax-box {
  position: relative;
  height: 300px;
  transition: all 2s ease;
}
.logo-paralax-box--orange {
  position: absolute;
  top: -30%;
  left: 55%;
  max-height: 210px;
  max-width: 210px;
}
.logo-paralax-box--pink {
  position: absolute;
  top: 0%;
  left: 25%;
  max-height: 200px;
  max-width: 200px;
}
.logo-paralax-box--sun-light {
  position: absolute;
  bottom: -20%;
  left: 40%;
  max-height: 320px;
  max-width: 320px;
}

@media only screen and (max-width: 850px) {
  .swirls-container {
    display: none;
  }
}

.nav-menu {
  font-family: "montserrat reg", sans-serif;
  font-size: 1rem;
  padding-right: 20px;
}
.nav-menu .menu-links {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  transform: translate(0%, 0%);
  height: 0;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}
.nav-menu .menu-links .button.button--primary {
  height: 0;
  padding: 0;
  overflow: hidden;
  color: black;
}
.nav-menu .menu-links .button.button--primary:hover {
  text-decoration: none;
}

.meta-overlay {
  display: none;
}
.meta-overlay__content .section {
  padding: 0 20px;
  margin: 0;
}
.meta-overlay__content h2 {
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-align: left;
  margin: 0 0 20px;
}
.meta-overlay__content h3 {
  font-size: 1.563rem;
  line-height: 1.875rem;
  margin: 10px 0;
}
@media only screen and (max-width: 850px) {
  .meta-overlay__content .section {
    padding: 10px;
  }
  .meta-overlay__content h2 {
    font-size: 1.25rem;
    line-height: 1.563rem;
  }
  .meta-overlay__content h3 {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 5px 0;
  }
}
.overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 20;
  transform-origin: top left;
  transform: translate(0%, -10%) rotate(-90deg);
  background: white;
  transition: all 0.4s ease-out;
  overflow: hidden;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.3);
}
.overlay.active--menu {
  box-shadow: unset;
  transform-origin: top left;
  transform: rotate(0deg);
}
.overlay-box {
  position: relative;
  height: inherit;
  max-width: 760px;
  margin: auto;
  overflow-x: hidden;
}
.overlay-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 40px 0;
}
.overlay-header h1 {
  margin: 0 auto;
}
@media only screen and (max-width: 480px) {
  .overlay-header h1 {
    font-size: 1.875rem;
  }
}

.section {
  position: relative;
  background-color: white;
  margin-top: 130px;
  padding: 20px;
  transition: all 0.4s;
  scroll-snap-align: start;
}
.section__content {
  max-width: 1200px;
  margin: auto;
}
.section__content--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 40px;
}
.section__content--grid.active > * {
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 20px;
}
@media only screen and (max-width: 850px) {
  .section__content--grid {
    display: block;
  }
}
.section__content--flex {
  width: 100%;
  display: flex;
  justify-content: center;
}
.section__content--flex-col {
  flex-direction: column;
  overflow-y: hidden;
}
.section__content--flex .logo {
  display: flex;
  justify-content: center;
  margin: 40px 0 10px;
  transition: all 0.4s;
  transform: translateY(350%) scale(0.2, 2);
}
.section__content--flex .logo.jump {
  animation: jump 1.2s forwards;
}
.section__content--flex .logo img {
  transition: all 0.4s;
  width: 80px;
  height: 80px;
}
.section__content--margin-top {
  margin-top: 100px;
}
@media only screen and (max-width: 850px) {
  .section__content--margin-top {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 480px) {
  .section__content--margin-top {
    margin-top: 40px;
  }
}
.section__item {
  position: relative;
}
.section__item:first-child {
  z-index: 10;
}
.section__text {
  margin: 50px auto;
}
@media only screen and (max-width: 1190px) {
  .section__text {
    max-width: 790px;
  }
}
@media only screen and (max-width: 850px) {
  .section__text {
    max-width: 440px;
  }
}
@media only screen and (max-width: 480px) {
  .section__text {
    width: 1005;
  }
}
.section--center .button.button__toogle {
  margin: 20px auto 0;
}
.section--sun-light {
  position: relative;
  padding: 20px;
  background-color: #FFF4EC;
}
.section--start {
  margin-top: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.section--end {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  padding-bottom: 0;
}
.section--overlay p {
  margin-bottom: 20px;
}
@media only screen and (max-width: 850px) {
  .section {
    margin-top: 50px;
    padding: 10px 20px;
  }
  .section__content--flex .logo {
    margin: 20px 0 5px;
  }
  .section__content--flex .logo img {
    width: 60px;
    height: 60px;
  }
  .section--start {
    margin-top: 80px;
    padding-top: 20px;
    padding-bottom: 80px;
  }
  .section--end {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 480px) {
  .section {
    padding: 5px 20px;
  }
  .section__content h2 {
    margin-top: 20px;
  }
  .section__content--flex .logo img {
    width: 50px;
    height: 50px;
  }
  .section--start {
    margin-top: 65px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .section--end {
    padding-bottom: 0px;
  }
}

.slide-container {
  position: relative;
  margin: auto;
  overflow: hidden;
  animation: 1s fadeFromMedium ease;
  width: 1200px;
}
.slide-container .slides {
  display: flex;
  width: 100%;
  transform: translate(0, 0);
  transition: all 0.4s ease;
}
.slide-container .slides .slide {
  width: 1200px;
  transition: all 0.4s ease-out;
}
@media only screen and (max-width: 1200px) {
  .slide-container .slides .slide {
    width: 800px;
  }
}
.slide-container .slides .slide .image-container {
  width: 1200px;
  padding: 0 5px;
  transition: all 0.4s ease-out;
}
@media only screen and (max-width: 1200px) {
  .slide-container .slides .slide .image-container {
    width: 800px;
  }
}
.slide-container .slides .slide .image-container__row {
  display: flex;
  justify-content: space-between;
}
.slide-container .slides .slide .image-container__row .image-overlay {
  position: relative;
  height: 355px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.slide-container .slides .slide .image-container__row .image-overlay:first-of-type {
  margin: 0 40px 40px 0;
}
.slide-container .slides .slide .image-container__row .image-overlay__image {
  width: inherit;
  height: inherit;
  transition: all 0.4s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide-container .slides .slide .image-container__row .image-overlay__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.slide-container .slides .slide .image-container__row .image-overlay .descr-overlay {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background: radial-gradient(ellipse at center, rgba(213, 26, 117, 0.8) 0%, rgba(255, 255, 255, 0) 50%);
  font-family: "montserrat bold", sans-serif;
  transition: all 0.2s ease-out;
  padding: 20px;
  text-align: center;
}
.slide-container .slides .slide .image-container__row .image-overlay .descr-overlay__title {
  font-size: 2.5rem;
}
.slide-container .slides .slide .image-container__row .image-overlay .descr-overlay__text {
  font-size: 1.25rem;
}
.slide-container .slides .slide .image-container__row .image-overlay .descr-overlay__divider {
  width: 100px;
  height: 40px;
  background: url(./assets/icons/divider.svg) no-repeat center;
}
.slide-container .slides .slide .image-container__row .image-overlay .descr-overlay:hover {
  backdrop-filter: blur(5px) saturate(0);
  background-color: rgba(213, 26, 117, 0.8);
  opacity: 1;
}
@media only screen and (max-width: 1200px) {
  .slide-container .slides .slide .image-container__row .image-overlay {
    height: 230px;
  }
  .slide-container .slides .slide .image-container__row .image-overlay .descr-overlay__title {
    font-size: 1.875rem;
  }
}
@media only screen and (max-width: 1200px) {
  .slide-container {
    width: 800px;
  }
}
@media only screen and (max-width: 850px) {
  .slide-container {
    display: none;
  }
}
.slide-container--mobile {
  width: 440px;
  display: none;
}
.slide-container--mobile .slides {
  width: inherit;
  transition: all 0.4s ease;
  margin-bottom: 20px;
}
.slide-container--mobile .slides .slide {
  width: inherit;
}
.slide-container--mobile .slides .slide .image-overlay {
  width: inherit;
  position: relative;
}
.slide-container--mobile .slides .slide .image-overlay__image {
  overflow: hidden;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin: 0 5px;
  height: 275px;
  transition: all 0.4s ease-out;
}
.slide-container--mobile .slides .slide .image-overlay__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}
.slide-container--mobile .slides .slide .image-overlay .descr-overlay {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background: radial-gradient(ellipse at center, rgba(213, 26, 117, 0.8) 0%, rgba(255, 255, 255, 0) 50%);
  font-family: "montserrat bold", sans-serif;
  transition: all 0.2s ease-out;
  padding: 20px;
  text-align: center;
}
.slide-container--mobile .slides .slide .image-overlay .descr-overlay__title {
  font-size: 1.875rem;
  text-align: center;
}
.slide-container--mobile .slides .slide .image-overlay .descr-overlay__text {
  font-size: 1.25rem;
}
.slide-container--mobile .slides .slide .image-overlay .descr-overlay__divider {
  width: 100px;
  height: 40px;
  background: url(./assets/icons/divider.svg) no-repeat center;
}
.slide-container--mobile .slides .slide .image-overlay .descr-overlay:hover {
  backdrop-filter: blur(5px) saturate(0);
  background-color: rgba(213, 26, 117, 0.8);
  opacity: 1;
}
@media only screen and (max-width: 850px) {
  .slide-container--mobile {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .slide-container--mobile {
    width: 335px;
  }
  .slide-container--mobile .slides .slide .image-overlay__image {
    height: 209.4px;
  }
}

.slogan {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 170px;
  width: 100%;
  transition: all 2s ease;
  opacity: 0;
}
.slogan.is-shown {
  opacity: 1;
  animation: bubblePopIn 1s cubic-bezier(1, -0.38, 0, 1.62);
}
.slogan-bubble {
  height: inherit;
  background-position: center;
  background-image: url(./assets/speech_bubble.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.slogan-bubble__text {
  font-family: "anton", sans-serif;
  overflow: hidden;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  white-space: nowrap;
  margin: 0 auto;
  font-size: 4rem;
  border-right: 0.2em solid #EA6B06;
  transition: all 0.4s ease;
  animation: blink-caret 0.75s step-end infinite;
}
@media only screen and (max-width: 850px) {
  .slogan {
    bottom: -7%;
    height: 120px;
  }
  .slogan-bubble__text {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .slogan {
    bottom: 0%;
    padding: 20px;
    height: 85px;
  }
  .slogan-bubble__text {
    font-size: 1.6rem;
    top: 80%;
  }
}

.intro-anim .slogan {
  bottom: -250px;
}
@media only screen and (max-width: 850px) {
  .intro-anim .slogan {
    bottom: -100px;
  }
}
@media only screen and (max-width: 480px) {
  .intro-anim .slogan {
    bottom: -80px;
  }
}

.title {
  color: #420A5D;
  font-family: "montserrat bold", sans-serif;
  margin: 20px 0 40px;
}
.title--margin-grid-special {
  margin: 50px 0 -20px 0;
  grid-column-start: 1;
  grid-column-end: 3;
}
.title--m-bottom-xxl {
  margin-bottom: 100px;
}
@media only screen and (max-width: 850px) {
  .title--m-bottom-xxl {
    margin-bottom: 50px;
  }
}
.title--m-bottom-l {
  margin-bottom: 40px;
}
.title--center {
  text-align: center;
}
.title__overline {
  font-size: 1.563rem;
  margin-bottom: 25px;
}
.title__overline--small {
  font-size: 1rem;
  margin-bottom: 10px;
}
.title__headline {
  font-size: 2.5rem;
  line-height: inherit;
}
.title__headline--small {
  font-size: 1.563rem;
}
@media only screen and (max-width: 850px) {
  .title__headline--small {
    margin-bottom: 40px;
  }
}