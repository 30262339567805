.overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: $-overlay;
  transform-origin: top left;
  transform: translate(0%,-10%) rotate(-90deg);
  background: $c-white;
  transition: all $s-time-04 ease-out;
  overflow: hidden;
  box-shadow: $sh-large;

  &.active {
    
    &--menu {
      box-shadow: unset;
      transform-origin: top left;
      transform:rotate(0deg)
    }
  }

  &-box {
    position: relative;
    height: inherit;
    max-width: 760px;
    margin: auto;
    overflow-x: hidden;
  }

  &-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: $-header;
    display: flex;
    align-items: center;
    background-color: $c-white;
    padding: 40px 0;

    h1 {
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: $m-tablet-width) {}
  @media only screen and (max-width: $m-mobile-width) {
    &-header {
      h1 {
        font-size: $f-xl;
      }
    }
  }
}