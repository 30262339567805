.section {
  position: relative;
  background-color: $c-white;
  margin-top: $u-xxxl;
  padding: 20px;
  transition: all $s-time-04;
  scroll-snap-align: start;

  &__content {
    max-width: $m-max-width;
    margin: auto;

    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $u-l $u-l;

      &.active > * {
        opacity: 1;
        width: 100%;
        height: 100%;
        padding: $u-m;
      }

      @media only screen and (max-width: $m-tablet-width) {
        display: block;
      }
    }

    &--flex {
      width: 100%;
      display: flex;
      justify-content: center;
      
      &-col {
        flex-direction: column;
        overflow-y: hidden;
      }

      .logo {
        display: flex;
        justify-content: center;
        margin: $u-l 0 $u-s;
        transition: all $s-time-04;
        transform: translateY(350%) scale(0.2, 2);
        
        &.jump {
          animation: jump 1.2s forwards;
        }

        img {
          transition: all $s-time-04;
          width: 80px;
          height: 80px;
        }
      }
    }

    &--margin-top {
      margin-top: $u-xxl;
      
      @media only screen and (max-width: $m-tablet-width) {
        margin-top: $u-xl;
      }

      @media only screen and (max-width: $m-mobile-width) {
        margin-top: $u-l;
      }
    }
  }

  &__item {
    position: relative;

    &:first-child {
      z-index: $-normal;
    }
  }

  &__text {
    margin: $u-xl auto;

    @media only screen and (max-width: 1190px) {
      max-width: 790px;
    }
    
    
    @media only screen and (max-width: $m-tablet-width) {
      max-width: 440px;
    }

    @media only screen and (max-width: $m-mobile-width) {
      width: 1005;
    }
  }

  &--center {
    .button.button__toogle {
      margin: $u-m auto 0;
    }
  }

  &--sun-light {
    position: relative;
    padding: $u-m;
    background-color: $c-ci-sun-light;
  }

  &--start {
    margin-top: 30px;
    padding-top: $u-l;
    padding-bottom: $u-l;
  }

  &--end {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: calc($u-xl / 2);
    padding-bottom: 0;
  }

  &--overlay {
    p {
      margin-bottom: $u-m;
    }
  }


  @media only screen and (max-width: $m-tablet-width) {
    margin-top: $u-xl;
    padding: $u-s $u-m;

    &__content {
      &--flex {
        .logo {
          margin: $u-m 0 $u-xs;
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
    &--start {
      margin-top: 80px;
      padding-top: $u-m;
      padding-bottom: 80px;
    }

    &--end {
      padding-bottom: 0px;
    }
  }

  @media only screen and (max-width: $m-mobile-width) {
    padding: $u-xs $u-m;

    &__content {
      h2 {
        margin-top: $u-m;
      }
    }

    &__content {
      &--flex {
        .logo {
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    &--start {
      margin-top: 65px;
      padding-top: $u-m;
      padding-bottom: 50px;
    }

    &--end {
      padding-bottom: 0px;
    }
  }
}