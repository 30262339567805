.logo-paralax-box {
  position: relative;
  height: 300px;
  transition: all $s-time-2 ease;
  
  &--orange {
    position: absolute;
    top: -30%;
    left: 55%;
    max-height: 210px;
    max-width: 210px;
  }
  
  &--pink {
    position: absolute;
    top: 0%;
    left: 25%;
    max-height: 200px;
    max-width: 200px;
  }
  
  &--sun-light {
    position: absolute;
    bottom: -20%;
    left: 40%;
    max-height: 320px;
    max-width: 320px;
  }
}

.swirls-container {
  @media only screen and (max-width: $m-tablet-width) {
    display: none;
  }
}