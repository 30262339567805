.slogan {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 170px;
  width: 100%;
  transition: all $s-time-2 ease;
  opacity: 0;

  &.is-shown {
    opacity: 1;
    animation: bubblePopIn 1s cubic-bezier(1,-0.38, 0, 1.62);
  }

  &-bubble {
    height: inherit;
    background-position: center;
    background-image: url(./assets/speech_bubble.svg);
    background-repeat: no-repeat;
    background-size: contain;
    
    &__text {
      @include font-slogan;
      overflow: hidden;
      position: absolute;
      top:60%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $c-white;
      white-space: nowrap;
      margin: 0 auto;
      font-size: 4rem;
      border-right: 0.2em solid $c-ci-sun; 
      transition: all $s-time-04 ease;
      animation: blink-caret .75s step-end infinite;
    }
  }

  @media only screen and (max-width: $m-tablet-width) {
    bottom: -7%;

    &-bubble {
      &__text {
        font-size: 3rem;
      }
    }

    height: 120px;
  }
  
  @media only screen and (max-width: $m-mobile-width) {
    bottom: 0%;
    padding: $u-m;

    &-bubble {
      &__text {
        font-size: 1.6rem;
        top: 80%;
      }

    }

    height: 85px;
  }
}

.intro-anim {
  .slogan { 
    bottom: -250px;
    
    @media only screen and (max-width: $m-tablet-width) {
      bottom: -100px;
    }
    @media only screen and (max-width: $m-mobile-width) {
      bottom: -80px;
    }
  }
}