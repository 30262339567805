.list {

  &--no-li-style {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
  }

  &__item {

    &--thick {
      @include font-headline
    }
    
    &--thin {
      @include font-nav

    }
  }
}