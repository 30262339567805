* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
	touch-action: manipulation;
  overflow-x: hidden;
}

body {
  position: relative;
  @include font-base;
  overflow-x: hidden;
  font-size: 16px;
  overflow-y: scroll;
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 92px;


  &.noAction {
    overflow: hidden;
  }
}

div {
  box-sizing: border-box;
}

p {
  line-height: $f-line;
}

a {
  text-decoration: none;
  color: $c-ci-blue-dark;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  margin:0 0 $u-m $u-m;
  list-style: inside;
  line-height: $f-line;
  list-style-position: outside;

  li {
    margin-bottom: $u-s;
  }
}

h1 {
  @include font-headline;
  font-size: $f-xxxl;
  margin-bottom: $u-xl;
  text-align: center;
  transition: all $s-time-02 ease-out;
}

h2 {
  @include font-headline;
  font-size: $f-xxl;
  text-align: center;
  margin: $u-l 0;
  color: $c-ci-blue-dark;
  transition: all $s-time-02 ease-out;
}

h3 {
  @include font-headline;
  font-size: $f-xl;
  margin-bottom: $u-m;
  color: $c-ci-blue-dark;
  transition: all $s-time-02 ease-out;
  line-height: $f-xxl;
}

h4 {
  @include font-headline;
  font-size: $f-l;
  margin-bottom: $u-s;
  transition: all $s-time-02 ease-out;
  line-height: $f-xl;
}

strong {
  @include font-headline;
}

:disabled {
  pointer-events: none;
  opacity: 0.5;
}
.upper {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

address {
  line-height: $f-line;
}
@media only screen and (max-width: $m-mobile-width) {

  body {
    font-size: $f-xs; 
    scroll-padding-top: 65px;
  }

  h1 {
    font-size: $f-xxl;
    margin-bottom: $u-m;
  }

  h2 {
    font-size: $f-xl;
    margin: $u-m 0;
  }
  
  h3 {
    font-size: $f-m;
    margin-bottom: $u-xs;
    line-height: $f-l;
  }
  
  h4 {
    font-size: $f-s;
    margin-bottom: $u-xs;
  }
}