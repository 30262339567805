.button {
  font-size: $f-s;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  transition: all $s-time-01 ease-out;
  
  &__title {
    color: $c-ci-pink;
  }
  
  &--primary {
    @include font-nav;
    border-radius: 5px;
    padding: 5px 15px;
    margin-left: $u-xs;
    
    &:hover {
      background-color: $c-black-10;
    }
    
    &.active {
      pointer-events: none;
    }

    &.active ,
    &:active {
      color: $c-white;
      background-color: $c-black-50;
    }
  }

  &--secondary {
    border-radius: $b-radius;
    padding: $u-m $u-l;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $u-l auto 0;
    
    
    &--sun {
      background-color: $c-ci-sun-light;
    }
    
    &--white {
      background-color: $c-white;
    }

    .button__img {
      margin: -20px -30px -20px 0;
      width: 50px;
      height: 50px;
      transform: rotate(-90deg);
      object-position: center;
      object-fit: contain;
      transition: all $s-time-01 ease;
    }

    &:hover {
      box-shadow: $sh-normal;
    }

    &.active {
      .button__img {
        transform: rotate(-270deg);
      }
    }
  }

  &--close {
    padding: $u-xs;
    margin: auto -32px auto $u-xs;
    width: $icon-xs;
    height: $icon-xs;
    border: 1px solid $c-ci-pink;
    border-radius: $icon-xs;
    transition: all $s-time-01 ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }


  &--arrow {
    width: $u-xl;
    height: $u-xl;
    border: 1px solid $c-white;
    border-radius: $u-xl;
    background-color: $c-white;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 auto;

    &:nth-of-type(1) {
      background-image: url(./assets/icons/arrow.svg);
    }

    &:nth-of-type(2) {
      transform: rotate(180deg);
      background-image: url(./assets/icons/arrow.svg);

      &:active {
        transform: scale(0.9) rotate(180deg);
      }
    }

    &:hover {
      border: 1px solid $c-ci-pink;
    }

    &:active {
      box-shadow: none;
      filter: brightness(0.89);
      transform: scale(0.9);
    }
  }

  &--burger {
    display: block;
    cursor: pointer;
    transition: all $s-time-01;

    span {
      display: block;
      width: $u-l;
      height: $u-xs;
      margin-left: $u-xl;
      margin-bottom: $u-xs;
      position: relative;

      background: $c-ci-pink;
      border-radius: $b-radius;

      transform-origin: 4px 0px;

      transition: all $s-time-01 ease;
    }
  }

  &:active {
    transform: scale(0.95);
  }
}

.burger-menu {
  #burger-btn {
    display: none;
  
    &:checked~label span {
      &:nth-last-child(1) {
        transform: rotate(-45deg) translate(-4px, -1px);
      }
  
      &:nth-last-child(2) {
        transform: rotate(45deg) scale(0);
      }
  
      &:nth-last-child(3) {
        transform: rotate(45deg);
      }
  
    }
    &:checked ~ .menu-links {
      height: 250px;
      transform: translate(0%, 0);
  
      .button.button--primary {
        margin: $u-s 0;
        height: 30px;
        padding: 8px 15px;
      }
    }
  }

  margin-top: $u-s;
}


.button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      display: none;
    }

    label {
      cursor: pointer;
      height: 10px;
      width: 30px;
      margin-left: 10px;
      border-radius: $b-radius;
      background-color: $c-ci-pink-20;
      transition: 0.2s ease;

      &.mobile {
        height: 20px;
        width: 5px;
        margin-left: 5px;
        pointer-events: none;
      }

      &:hover {
        border-radius: $b-radius;
        height: 30px;
      }
    }

    :checked + label {
      pointer-events: none;
      background-color: $c-ci-pink;
      width: 10px;
      
      &.mobile {
        height: 5px;
        width: 5px;
      }
    }
  }
}