// colors
$c-black: black;
$c-white: white;

$c-black-10: rgba(0,0,0,0.1);
$c-black-30: rgba(0,0,0,0.3);
$c-black-50: rgba(0,0,0,0.5);
$c-white-50: rgba(255,255,255, 0.5);
$c-white-70: rgba(255,255,255, 0.7);
$c-white-80: rgba(255,255,255, 0.8);
$c-white-90: rgba(255,255,255, 0.9);

$c-ci-sun:#EA6B06;
$c-ci-sunset:#b20505;
$c-ci-sun-light: #FFF4EC;
$c-ci-pink: #D41A75;
$c-ci-pink-80: rgba(213, 26, 117, 0.8);
$c-ci-pink-50: rgba(213, 26, 117, 0.5);
$c-ci-pink-20: rgba(213, 26, 117, 0.2);
$c-ci-blue-dark: #420A5D;

// gradients

$g-sun-set: radial-gradient(ellipse at center,$c-ci-sun 1%,$c-ci-sunset 100%);;
$g-pink-trans: radial-gradient(ellipse at center, $c-ci-pink-80 0%,rgba(255,255,255,0) 50%);
$g-white-trans: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%);


// shadows
$sh-normal: 0px 3px 6px 0px rgba(0,0,0,0.3);
$sh-large: 0px 10px 15px 0px rgba(0,0,0,0.3);
$sh-light: 0px 0px 50px 0px rgba(0,0,0,0.2);
$sh-hard-right: 6px 6px 0px 0px rgba(0,0,0,0.2);
$sh-hard-left: -6px 6px 0px 0px rgba(0,0,0,0.2);
