.title {
  color: $c-ci-blue-dark;
  @include font-headline;
  margin: $u-m 0 $u-l;

  &--margin-grid-special {
    margin: 50px 0 -20px 0;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &--m-bottom-xxl {
    margin-bottom: $u-xxl;
    
    @media only screen and (max-width: $m-tablet-width) {
      margin-bottom: $u-xl;

    }
  }

  &--m-bottom-l {
    margin-bottom: $u-l;
  }

  &--center {
    text-align: center;
  }
  
  &__overline {
    font-size: $f-l;
    margin-bottom: $u-ml;
    
    &--small {
      font-size: $f-s;
      margin-bottom: $u-s;
    }
  }
  
  &__headline {
    font-size: $f-xxl;
    line-height: inherit;

    &--small {
      font-size: $f-l;

      @media only screen and (max-width: $m-tablet-width) {
        margin-bottom: $u-l;
      }

    }
    
  }
}